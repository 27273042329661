// API Configuration
export const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001/api';

// Authentication
export const TOKEN_KEY = 'auth_token';

// Other configuration constants
export const APP_NAME = 'Interviewed';
export const DEFAULT_AVATAR = '/assets/images/avatar.jpg';

// API Endpoints
export const ENDPOINTS = {
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    VERIFY: '/auth/verify',
  },
  USERS: {
    BASE: '/users',
    PROFILE: '/users/profile',
    EXISTS: '/users/exists',
  },
  PROFILES: {
    BASE: '/profiles',
  },
  APPLICATIONS: {
    BASE: '/applications',
  },
};

// Validation
export const PASSWORD_MIN_LENGTH = 6;
export const NAME_MIN_LENGTH = 2;

// UI Constants
export const TOAST_DURATION = 5000; // milliseconds
export const SIDEBAR_WIDTH = 240;
export const MOBILE_SIDEBAR_WIDTH = 65; 