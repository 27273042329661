import React, { useState } from "react";
import { 
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  InputAdornment,
  IconButton,
  Link as MuiLink,
  CircularProgress,
} from "@mui/material";
import {
  Email,
  Visibility,
  VisibilityOff,
  LockOutlined,
} from "@mui/icons-material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Link, useHistory } from "react-router-dom";
import { authenticateUser, checkAuthStatus } from "../library/store/authentication";
import { useDispatch, useSelector } from "react-redux";

export default function LoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const authError = useSelector((state) => state.authentication.value.error);

  const LoginSchema = Yup.object().shape({
    userid: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      userid: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const result = await dispatch(authenticateUser({
          userid: values.userid,
          password: values.password
        })).unwrap();
        
        if (result.success) {
          history.push("/dashboard");
        }
      } catch (error) {
        console.error("Login failed:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, getFieldProps } = formik;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await formik.submitForm();
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: 2,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            '&:hover': {
              boxShadow: '0 0 20px rgba(144, 202, 249, 0.16)',
            },
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
              Welcome Back
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Sign in to continue to your account
            </Typography>
          </Box>

          <FormikProvider value={formik}>
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
                <TextField
                  fullWidth
                  label="Email"
                  {...getFieldProps('userid')}
                  error={Boolean(touched.userid && errors.userid)}
                  helperText={touched.userid && errors.userid}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email sx={{ color: 'text.secondary' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  {...getFieldProps('password')}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined sx={{ color: 'text.secondary' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <MuiLink
                    component={Link}
                    to="/forgot-password"
                    variant="body2"
                    sx={{
                      color: 'primary.main',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Forgot Password?
                  </MuiLink>
                </Box>

                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting ? "true" : undefined}
                  disabled={isSubmitting}
                  sx={{
                    mt: 2,
                    bgcolor: 'primary.main',
                    color: 'common.white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                    height: 48,
                  }}
                >
                  {isSubmitting ? 'Signing in...' : 'Sign In'}
                </Button>

                <Typography 
                  variant="body2" 
                  align="center"
                  sx={{ mt: 2 }}
                >
                  Don't have an account?{' '}
                  <Link 
                    to="/register"
                    style={{ 
                      color: '#90caf9',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Get Started
                  </Link>
                </Typography>
              </Box>
            </form>
          </FormikProvider>
        </Paper>
      </Container>
    </Box>
  );
}
