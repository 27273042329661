import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Chip,
  Autocomplete,
} from '@mui/material';

const suggestedCompanies = [
  'Google',
  'Microsoft',
  'Amazon',
  'Apple',
  'Meta',
  'Netflix',
  'Tesla',
  'IBM',
  'Oracle',
  'Intel'
];

export default function TargetCompaniesForm({ formData, setFormData }) {
  const handleChange = (event, newValue) => {
    setFormData({
      ...formData,
      targetCompanies: newValue
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Target Companies
      </Typography>
      
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Select companies you're interested in working for
      </Typography>

      <Autocomplete
        multiple
        id="target-companies"
        options={suggestedCompanies}
        value={formData.targetCompanies}
        onChange={handleChange}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              color="primary"
              variant="outlined"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Companies"
            placeholder="Add company"
          />
        )}
      />
    </Box>
  );
} 