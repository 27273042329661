import axiosInstance from './axiosConfig';

export const applicationApi = {
  createApplication: async (applicationData) => {
    try {
      const response = await axiosInstance.post('/applications', applicationData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUserApplications: async (userId) => {
    try {
      const response = await axiosInstance.get(`/applications/user/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateApplicationStatus: async (applicationId, statusData) => {
    try {
      const response = await axiosInstance.patch(
        `/applications/${applicationId}/status`,
        statusData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getApplicationDetails: async (applicationId) => {
    try {
      return await axiosInstance.get(`/applications/${applicationId}`);
    } catch (error) {
      throw error;
    }
  },

  addInterviewToApplication: async (applicationId, interviewData) => {
    try {
      return await axiosInstance.post(
        `/applications/${applicationId}/interviews`,
        interviewData
      );
    } catch (error) {
      throw error;
    }
  },

  updateInterviewStatus: async (applicationId, interviewId, statusData) => {
    try {
      return await axiosInstance.patch(
        `/applications/${applicationId}/interviews/${interviewId}`,
        statusData
      );
    } catch (error) {
      throw error;
    }
  },
}; 