import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  LinearProgress,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Timeline,
  Assignment,
  People,
  TrendingUp,
} from "@mui/icons-material";
import { profileApi } from "../api/profileApi";
import { toggleSidebar } from '../library/store/sidebar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function DashboardPage() {
  const [profileData, setProfileData] = useState(null);
  const sidebarOpen = useSelector((state) => state.sidebar.value.isOpen);
  const userData = useSelector((state) => state.authentication?.value?.loginData);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication?.value?.token);
  const [strengthDialog, setStrengthDialog] = useState(false);
  const [strengthDetails, setStrengthDetails] = useState({ strength: 0, improvements: [] });

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();

    if (token && mounted) {
      dispatch(toggleSidebar(true))

      localStorage.setItem('dashboardLoaded', 'true');
      
      console.log("dashboard loaded")
    }

    const fetchProfileData = async () => {
      try {
        if (!userData?._id) return;
        
        const [profileResponse, strengthResponse] = await Promise.all([
          profileApi.getProfile(userData._id, abortController.signal),
          profileApi.getProfileStrength(userData._id)
        ]);

        if (mounted) {
          if (profileResponse?.data) {
            setProfileData(profileResponse.data);
          }
          if (strengthResponse?.data) {
            setStrengthDetails(strengthResponse.data);
          }
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error('Error fetching profile data:', error);
        }
      }
    };

    fetchProfileData();

    const refreshInterval = setInterval(fetchProfileData, 30000); // Refresh every 30 seconds
    return () => {
      mounted = false;
      abortController.abort();
      clearInterval(refreshInterval);
    };
  }, [userData, dispatch, token]);

  const handleStrengthClick = async () => {
    try {
      const response = await profileApi.getProfileStrength(userData?._id);
      setStrengthDetails(response.data);
      setStrengthDialog(true);
    } catch (error) {
      console.error('Error fetching profile strength:', error);
    }
  };

  const ProfileStrengthDialog = () => (
    <Dialog 
      open={strengthDialog} 
      onClose={() => setStrengthDialog(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Profile Strength Analysis</DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center', my: 2 }}>
          <Typography variant="h3" color="primary">
            {strengthDetails.strength}%
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Profile Strength
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Areas for Improvement
        </Typography>
        <List>
          {strengthDetails.improvements.map((improvement, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <ArrowForwardIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={improvement} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setStrengthDialog(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const stats = [
    { 
      icon: <Timeline />, 
      title: 'Profile Strength', 
      value: `${profileData?.profileStrength || 0}%`, 
      color: '#90caf9',
      onClick: handleStrengthClick
    },
    { 
      icon: <Assignment />, 
      title: 'Tasks Completed', 
      value: `${profileData?.completedTasks?.length || 0}`, 
      color: '#81c784' 
    },
    { 
      icon: <People />, 
      title: 'Network Size', 
      value: profileData?.networkConnections?.length || 0, 
      color: '#ffb74d' 
    },
    { 
      icon: <TrendingUp />, 
      title: 'Skills Gained', 
      value: profileData?.skillAchievements?.length || 0, 
      color: '#f48fb1' 
    },
  ];

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        p: { xs: 2, md: 4 },
        transition: 'margin-left 0.3s ease, width 0.3s ease',
        marginLeft: { 
          xs: sidebarOpen ? '5vw' : '2vw',
          md: sidebarOpen ? '5vw' : '2vw'
        },
        width: {
          xs: `calc(100% - ${sidebarOpen ? '135px' : '40px'})`,
          md: `calc(100% - ${sidebarOpen ? '135px' : '40px'})`
        },
        minHeight: '100vh',
        bgcolor: 'background.default',
      }}
    >
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 4, 
          fontWeight: 600,
          fontSize: { xs: '1.5rem', md: '2rem' },
          color: '#fff',  // White text
          transition: 'all 0.3s ease',
        }}
      >
        {new Date().getHours() < 12 ? 'Good Morning' : new Date().getHours() < 18 ? 'Good Afternoon' : 'Good Evening'}, {userData?.name || 'Guest'}!
      </Typography>

      <Grid 
        container 
        spacing={3} 
        sx={{ 
          mb: 4,
          width: '100%',
          margin: 0,
          transition: 'all 0.3s ease',
        }}
      >
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={3} key={stat.title}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 2,
                bgcolor: 'background.paper',
                border: '1px solid rgba(255, 255, 255, 0.12)',
                height: '100%',
                cursor: stat.onClick ? 'pointer' : 'default',
              }}
              onClick={stat.onClick}
            >
              <Box sx={{ 
                color: stat.color, 
                mb: 2,
                '& svg': { fontSize: 40 }
              }}>
                {stat.icon}
              </Box>
              <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                {stat.value}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {stat.title}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ marginTop: '16px' }} spacing={3}>
        <Grid item xs={12}  md={8}>
          <Card sx={{ height: '100%', marginLeft: '24px', borderRadius: '16px' }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Your Career Journey
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ mb: 1 }}>Overall Progress</Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={60} 
                  sx={{ 
                    height: 8, 
                    borderRadius: 4,
                    bgcolor: 'rgba(144, 202, 249, 0.16)',
                    '& .MuiLinearProgress-bar': {
                      bgcolor: 'primary.main',
                    }
                  }} 
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    Continue Learning
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    Practice Interview
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', borderRadius: '16px'}}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Quick Actions
              </Typography>
              <Button
                variant="text"
                fullWidth
                sx={{ 
                  justifyContent: 'flex-start', 
                  mb: 1,
                  '&:hover': {
                    bgcolor: 'rgba(144, 202, 249, 0.08)',
                  }
                }}
              >
                Update Resume
              </Button>
              <Button
                variant="text"
                fullWidth
                sx={{ justifyContent: 'flex-start', mb: 1 }}
              >
                Browse Jobs
              </Button>
              <Button
                variant="text"
                fullWidth
                sx={{ justifyContent: 'flex-start', mb: 1 }}
              >
                Network
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ProfileStrengthDialog />
    </Box>
  );
}
