import { API_BASE_URL, ENDPOINTS } from '../config';
import axiosInstance from './axiosConfig';

export const userApi = {
  getUsers: async () => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.USERS.BASE);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  getUser: async (id) => {
    try {
      const response = await axiosInstance.get(`${ENDPOINTS.USERS.BASE}/${id}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  createUser: async (userData) => {
    try {
      const response = await axiosInstance.post(ENDPOINTS.USERS.BASE, userData);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      }
      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }
      throw new Error('Registration failed. Please try again.');
    }
  },

  updateUser: async (userId, userData) => {
    if (!userId) {
      throw new Error('User ID is required');
    }
    return axiosInstance.put(`${ENDPOINTS.USERS.BASE}/${userId}`, userData);
  },

  deleteUser: async (id) => {
    try {
      const response = await axiosInstance.delete(`${ENDPOINTS.USERS.BASE}/${id}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  login: async (credentials) => {
    try {
      const response = await axiosInstance.post(ENDPOINTS.AUTH.LOGIN, credentials);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || new Error('Login failed. Please try again.');
    }
  },

  verifyToken: async () => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.AUTH.VERIFY);
      const data = response.data;
      
      if (!data || !data.success || !data.user) {
        throw new Error('Invalid response format');
      }
      
      return data;
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
      }
      throw error.response?.data || error;
    }
  },

  checkUserExists: async (userId) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      const response = await axiosInstance.get(`${ENDPOINTS.USERS.BASE}/exists/${userId}`);
      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }
      throw error;
    }
  },
};
