import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Grid,
} from '@mui/material';
import {
  PlayArrow,
  CheckCircle,
  RadioButtonUnchecked,
  ArrowBack,
  Assignment,
  OndemandVideo,
  Quiz,
} from '@mui/icons-material';

// Mock data - replace with actual API call
const getModuleContent = (moduleId) => ({
  id: moduleId,
  title: 'Technical Interview Fundamentals',
  description: 'Master the basics of data structures, algorithms, and problem-solving techniques',
  sections: [
    {
      title: 'Introduction to Data Structures',
      type: 'video',
      duration: '15 mins',
      content: 'Video content about data structures basics',
      completed: false,
    },
    {
      title: 'Array Manipulation Techniques',
      type: 'lesson',
      duration: '20 mins',
      content: 'Learn various array manipulation techniques and their time complexities',
      completed: false,
    },
    {
      title: 'Practice Problems',
      type: 'exercise',
      duration: '30 mins',
      content: 'Solve array-based coding problems',
      completed: false,
    },
    {
      title: 'Understanding Time Complexity',
      type: 'video',
      duration: '10 mins',
      content: 'Video explaining Big O notation',
      completed: false,
    },
    {
      title: 'Module Quiz',
      type: 'quiz',
      duration: '15 mins',
      content: 'Test your knowledge',
      completed: false,
    },
  ],
});

const getContentIcon = (type) => {
  switch (type) {
    case 'video':
      return <OndemandVideo />;
    case 'lesson':
      return <Assignment />;
    case 'quiz':
      return <Quiz />;
    case 'exercise':
      return <Assignment />;
    default:
      return <RadioButtonUnchecked />;
  }
};

export default function LearningModule() {
  const { moduleId } = useParams();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  
  const moduleData = getModuleContent(moduleId);
  
  const handleNext = () => {
    setCompleted({ ...completed, [activeStep]: true });
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleComplete = () => {
    setCompleted({ ...completed, [activeStep]: true });
    // TODO: Save progress to backend
    history.push('/learning');
  };

  const calculateProgress = () => {
    const completedSteps = Object.keys(completed).length;
    return (completedSteps / moduleData.sections.length) * 100;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton 
          onClick={() => history.push('/learning')}
          sx={{ mr: 2 }}
        >
          <ArrowBack />
        </IconButton>
        <Box>
          <Typography variant="h4" class="text-white" component="h1">
            {moduleData.title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {moduleData.description}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {moduleData.sections.map((section, index) => (
                <Step key={section.title} completed={completed[index]}>
                  <StepLabel>
                    <Typography variant="h6">{section.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {section.duration}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <Typography>{section.content}</Typography>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          onClick={
                            index === moduleData.sections.length - 1
                              ? handleComplete
                              : handleNext
                          }
                          sx={{ mr: 1 }}
                        >
                          {index === moduleData.sections.length - 1
                            ? 'Finish'
                            : 'Complete & Continue'}
                        </Button>
                        {index > 0 && (
                          <Button onClick={handleBack}>
                            Back
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Module Progress
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {calculateProgress()}% Complete
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {Object.keys(completed).length}/{moduleData.sections.length} Sections
                  </Typography>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={calculateProgress()} 
                  sx={{ height: 8, borderRadius: 4 }}
                />
              </Box>

              <Divider sx={{ my: 2 }} />

              <Typography variant="h6" gutterBottom>
                Content Overview
              </Typography>
              <List dense>
                {moduleData.sections.map((section, index) => (
                  <ListItem
                    key={section.title}
                    sx={{
                      borderRadius: 1,
                      mb: 0.5,
                      bgcolor: activeStep === index ? 'rgba(144, 202, 249, 0.08)' : 'transparent',
                    }}
                  >
                    <ListItemIcon>
                      {completed[index] ? (
                        <CheckCircle color="primary" />
                      ) : (
                        getContentIcon(section.type)
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={section.title}
                      secondary={section.duration}
                      primaryTypographyProps={{
                        variant: 'body2',
                        color: completed[index] ? 'primary' : 'text.primary',
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
} 