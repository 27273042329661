import axiosInstance from './axiosConfig';

export const companyApi = {
  getCompanies: async () => {
    try {
      const response = await axiosInstance.get('/companies');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCompany: async (id) => {
    try {
      const response = await axiosInstance.get(`/companies/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createCompany: async (companyData) => {
    try {
      const response = await axiosInstance.post('/companies', companyData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateCompany: async (id, companyData) => {
    try {
      const response = await axiosInstance.put(`/companies/${id}`, companyData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteCompany: async (id) => {
    try {
      const response = await axiosInstance.delete(`/companies/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getJobPostings: async (filters = {}) => {
    try {
      const queryParams = new URLSearchParams(filters).toString();
      const response = await axiosInstance.get(`/companies/jobs/search?${queryParams}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getCompanyPositions: async (companyId) => {
    try {
      const response = await axiosInstance.get(`/companies/${companyId}/positions`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}; 