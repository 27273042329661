import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  MenuItem,
  Autocomplete,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { applicationApi } from '../../../api/applicationApi';
import { companyApi } from '../../../api/companyApi';
import { useSelector } from 'react-redux';

const applicationStatuses = [
  'Draft',
  'Submitted',
  'Under Review',
  'Interview',
  'Offer',
  'Rejected'
];

export default function NewApplicationModal({ open, onClose, onSubmit }) {
  const userData = useSelector((state) => state.authentication?.value?.loginData);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [availablePositions, setAvailablePositions] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [formData, setFormData] = useState({
    position: '',
    companyName: '',
    companyId: '',
    jobPostingId: '',
    status: 'Draft',
    notes: '',
    userId: userData?._id
  });

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await companyApi.getCompanies();
      setCompanies(response.data || []);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleCompanyChange = async (event, newValue) => {
    setSelectedCompany(newValue);
    if (newValue) {
      setFormData(prev => ({
        ...prev,
        companyId: newValue._id,
        companyName: newValue.name,
        position: '' // Reset position when company changes
      }));
      
      // Fetch available positions for selected company
      try {
        const response = await companyApi.getCompanyPositions(newValue._id);
        setAvailablePositions(response.data || []);
      } catch (error) {
        console.error('Error fetching positions:', error);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await applicationApi.createApplication(formData);
      onSubmit();
      onClose();
      setFormData({
        position: '',
        companyName: '',
        companyId: '',
        jobPostingId: '',
        status: 'Draft',
        notes: '',
        userId: userData?._id
      });
      setSelectedCompany(null);
    } catch (error) {
      console.error('Error creating application:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Application</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Autocomplete
              options={companies}
              getOptionLabel={(option) => option.name}
              value={selectedCompany}
              onChange={handleCompanyChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  required
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.logo && (
                    <img
                      src={option.logo}
                      alt={option.name}
                      style={{ width: 20, height: 20, marginRight: 10 }}
                    />
                  )}
                  <Typography variant="body1">{option.name}</Typography>
                </Box>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Position"
              value={formData.position}
              onChange={(e) => setFormData({ 
                ...formData, 
                position: e.target.value,
                jobPostingId: availablePositions.find(p => p.title === e.target.value)?._id
              })}
              disabled={!selectedCompany}
            >
              {availablePositions.map((position) => (
                <MenuItem key={position._id} value={position.title}>
                  {position.title} - {position.department}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Status"
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
            >
              {applicationStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Notes"
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          variant="contained" 
          onClick={handleSubmit}
          disabled={loading || !formData.companyId || !formData.position}
        >
          {loading ? <CircularProgress size={24} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
} 