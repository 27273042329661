import React, { useState, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./library/helper";
import ToastManager from "./components/toast";
import SideBar from "./components/sidebar";
import { ThemeProvider, createTheme } from '@mui/material';
import { publicRoutes, privateRoutes, commonRoutes } from "./routes";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

const DRAWER_WIDTH = 240;
const MINI_DRAWER_WIDTH = 65;

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#ce93d8',
    },
    background: {
      default: '#0a1929',
      paper: '#0d2137',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
});

function App() {
  const isAuthPage = window.location.pathname === "/" || 
                    window.location.pathname === "/login" || 
                    window.location.pathname === "/register" ||
                    window.location.pathname === "/forgot-password";

  const sidebarOpen = useSelector((state) => state.sidebar.value.isOpen);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box 
          sx={{ 
            display: 'flex',
            minHeight: '100vh',
            bgcolor: 'background.default',
          }}
        >
          {!isAuthPage && <SideBar />}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              ml: isAuthPage ? 0 : (sidebarOpen ? DRAWER_WIDTH : MINI_DRAWER_WIDTH) + 'px',
              width: isAuthPage ? '100%' : `calc(100% - ${sidebarOpen ? DRAWER_WIDTH : MINI_DRAWER_WIDTH}px)`,
              minHeight: '100vh',
              bgcolor: 'background.default',
              transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            <Switch>
              {/* Public Routes */}
              {publicRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}

              {/* Private Routes */}
              {privateRoutes.map((route) => (
                <PrivateRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}

              {/* Common Routes (like 404) */}
              {commonRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </Box>
        </Box>
      </Router>
      <ToastManager />
    </ThemeProvider>
  );
}

export default App;