import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    isOpen: false
  }
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.value.isOpen = action.payload !== undefined ? action.payload : !state.value.isOpen;
    }
  },
});

export const { toggleSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
