import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  LinearProgress,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import ApplicationCard from './components/ApplicationCard';
import ApplicationStats from './components/ApplicationStats';
import NewApplicationModal from './components/NewApplicationModal';
import { useSelector } from 'react-redux';
import { applicationApi } from '../../api/applicationApi';

export default function ApplicationTracker() {
  const history = useHistory();
  const [applications, setApplications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    interviews: 0,
    offers: 0,
  });

  const { isLogged: isAuthenticated, loginData: userData } = useSelector(
    (state) => state.authentication.value
  );

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      if (!userData?._id) return;

      try {
        setLoading(true);
        setError(null);
        
        const response = await applicationApi.getUserApplications(userData._id);
        
        if (mounted && Array.isArray(response)) {
          setApplications(response);
          calculateStats(response);
        }
      } catch (error) {
        console.error('Error fetching applications:', error);
        if (mounted) {
          setError(error.message || 'Error fetching applications');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [userData?._id]);

  const calculateStats = (apps) => {
    const newStats = {
      total: apps.length,
      active: apps.filter(app => ['Submitted', 'Under Review', 'Interview'].includes(app.status)).length,
      interviews: apps.filter(app => app.status === 'Interview').length,
      offers: apps.filter(app => app.status === 'Offer').length,
    };
    setStats(newStats);
  };

  const handleApplicationUpdate = async () => {
    if (userData?._id) {
      try {
        const response = await applicationApi.getUserApplications(userData._id);
        if (Array.isArray(response)) {
          setApplications(response);
          calculateStats(response);
        }
      } catch (error) {
        console.error('Error updating applications:', error);
      }
    }
  };

  const EmptyState = () => (
    <Box 
      sx={{ 
        textAlign: 'center', 
        py: 8,
        px: 3,
        bgcolor: 'background.paper',
        borderRadius: 2,
        border: '1px dashed',
        borderColor: 'divider'
      }}
    >
      <Typography variant="h6" gutterBottom>
        No Applications Yet
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Start tracking your job applications by clicking the "New Application" button above.
      </Typography>
      <Button
        variant="contained"
        startIcon={<Add />}
        onClick={() => setIsModalOpen(true)}
      >
        Add Your First Application
      </Button>
    </Box>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h4" class="text-white" component="h1">
          Application Tracker
        </Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setIsModalOpen(true)}
        >
          New Application
        </Button>
      </Box>

      <ApplicationStats stats={stats} />

      <Paper sx={{ p: 2, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Recent Applications
        </Typography>
        {loading ? (
          <LinearProgress />
        ) : applications.length === 0 ? (
          <EmptyState />
        ) : (
          <Grid container spacing={2}>
            {applications.map((application) => (
              <Grid item xs={12} md={6} key={application._id}>
                <ApplicationCard
                  application={application}
                  onStatusUpdate={handleApplicationUpdate}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>

      <NewApplicationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleApplicationUpdate}
      />
    </Container>
  );
} 