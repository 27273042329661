import { ENDPOINTS } from '../config';
import axiosInstance from './axiosConfig';

export const profileApi = {
  getProfile: async (userId, signal) => {
    try {
      const response = await axiosInstance.get(`/profiles/${userId}`, { signal });
      return response.data;
    } catch (error) {
      if (error.name === 'CanceledError') {
        return;
      }
      console.error('Profile API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  createProfile: async (userId, profileData, signal) => {
    try {
      const response = await axiosInstance.post(`/profiles/${userId}`, profileData, { signal });
      return response.data;
    } catch (error) {
      if (error.name === 'CanceledError') {
        return;
      }
      console.error('Profile Creation Error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  },

  updateProfile: async (userId, profileData, signal) => {
    try {
      const response = await axiosInstance.put(`/profiles/${userId}`, profileData, { signal });
      return response.data;
    } catch (error) {
      if (error.name === 'CanceledError') {
        return;
      }
      console.error('Profile Update Error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  },

  getProfileStrength: async (userId) => {
    try {
      const response = await axiosInstance.get(`/profiles/${userId}/strength`);
      return response.data;
    } catch (error) {
      console.error('Profile Strength Error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  }
}; 