import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Box,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Dashboard,
  Person,
  School,
  Work,
  Assignment,
  Forum,
  VideoCall,
  Assessment,
  TrackChanges,
  Menu as MenuIcon,
  ChevronLeft,
} from "@mui/icons-material";
import { toggleSidebar } from '../library/store/sidebar';

const DRAWER_WIDTH = 240;
const MINI_DRAWER_WIDTH = 65;
const ICON_SIZE = 24;

const ROUTES_WITHOUT_SIDEBAR = [
  '/questionnaire',
  '/login',
  '/register',
  '/forgot-password'
];

const getIcon = (path) => {
  switch (path) {
    case '/dashboard':
      return <Dashboard />;
    case '/profile':
      return <Person />;
    case '/learning':
      return <School />;
    case '/jobs':
      return <Work />;
    case '/applications':
      return <TrackChanges />;
    case '/resume':
      return <Assignment />;
    case '/interviews':
      return <VideoCall />;
    case '/community':
      return <Forum />;
    case '/assessments':
      return <Assessment />;
    default:
      return null;
  }
};

export default function SideBar() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.sidebar.value.isOpen);
  const userData = useSelector((state) => state?.authentication?.value?.loginData) || {};
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    if (ROUTES_WITHOUT_SIDEBAR.some(route => location.pathname.includes(route))) {
    }
  }, [location.pathname, dispatch]);

  if (ROUTES_WITHOUT_SIDEBAR.some(route => location.pathname.includes(route))) {
    return null;
  }

  const handleDrawerToggle = () => {
    dispatch(toggleSidebar());
  };

  const menuItems = [
    { text: 'Dashboard', path: '/dashboard' },
    { text: 'Profile', path: '/profile' },
    { text: 'Learning Modules', path: '/learning' },
    { text: 'Job Search', path: '/jobs' },
    { text: 'Application Tracker', path: '/applications' },
    { text: 'Resume Builder', path: '/resume' },
    { text: 'Mock Interviews', path: '/interviews' },
    { text: 'Community', path: '/community' },
    { text: 'Assessments', path: '/assessments' },
  ];

  return (
    <Box
      component="nav"
      sx={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 1200,
      }}
    >
      
      <IconButton
        onClick={handleDrawerToggle}
        sx={{
          position: 'fixed',
          left: isOpen ? DRAWER_WIDTH / 2 - 20 : MINI_DRAWER_WIDTH / 2 - 20,
          top: 10,
          zIndex: 1300,
          backgroundColor: theme.palette.background.paper,
          color: '#fff',
          '&:hover': {
            backgroundColor: 'rgba(144, 202, 249, 0.08)',
          },
          transition: 'left 0.3s ease',
        }}
      >
        {isOpen ? <ChevronLeft /> : <MenuIcon />}
      </IconButton>

      <Drawer
        variant="permanent"
        open={isOpen}
        sx={{
          height: '100%',
          '& .MuiDrawer-paper': {
            position: 'relative',
            width: isOpen ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
            transition: 'width 0.3s ease',
            height: '100vh',
            boxSizing: 'border-box',
            border: 'none',
            backgroundColor: theme.palette.background.paper,
            overflowX: 'hidden',
          },
        }}
      >
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          pt: 7,
        }}>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            gap: 2,
          }}>
            <Avatar
              sx={{
                width: isOpen ? 80 : 40,
                height: isOpen ? 80 : 40,
                bgcolor: 'primary.dark',
                transition: 'all 0.3s ease',
              }}
            >
              {userData?.name?.[0] || 'G'}
            </Avatar>
            {isOpen && (
              <Box>
                <Typography variant="subtitle1" noWrap sx={{ color: '#fff' }}>
                  {userData?.name || 'Guest'}
                </Typography>
              </Box>
            )}
          </Box>

          <Divider sx={{ my: 1 }} />

          <List sx={{ flex: 1, px: 1 }}>
            {menuItems.map((item) => (
              <ListItem
                component={Link}
                to={item.path}
                key={item.text}
                selected={location.pathname === item.path}
                sx={{
                  minHeight: 48,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  px: 2,
                  borderRadius: 1,
                  mb: 0.5,
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: 'rgba(144, 202, 249, 0.08)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(144, 202, 249, 0.16)',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 2 : 'auto',
                    color: '#fff',
                  }}
                >
                  {getIcon(item.path)}
                </ListItemIcon>
                {isOpen && <ListItemText primary={item.text} />}
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
