import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthenticatedLayout from '../components/AuthenticatedLayout';

// Token management functions
export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');
export const checkToken = () => !!getToken();

// PrivateRoute component
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <AuthenticatedLayout>
          <Component {...props} />
        </AuthenticatedLayout>
      )}
    />
  );
};
