import React from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
} from '@mui/material';

const educationLevels = [
  'High School',
  'Associate Degree',
  'Bachelor\'s Degree',
  'Master\'s Degree',
  'PhD',
  'Other'
];

export default function EducationForm({ formData, setFormData }) {
  const [education, setEducation] = React.useState({
    level: '',
    institution: '',
    field: '',
    graduationYear: ''
  });

  const handleAdd = () => {
    if (education.level && education.institution) {
      setFormData({
        ...formData,
        education: [...formData.education, education]
      });
      setEducation({
        level: '',
        institution: '',
        field: '',
        graduationYear: ''
      });
    }
  };

  const handleRemove = (index) => {
    const newEducation = [...formData.education];
    newEducation.splice(index, 1);
    setFormData({
      ...formData,
      education: newEducation
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Education Details
      </Typography>
      
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="Education Level"
            value={education.level}
            onChange={(e) => setEducation({ ...education, level: e.target.value })}
          >
            {educationLevels.map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Institution"
            value={education.institution}
            onChange={(e) => setEducation({ ...education, institution: e.target.value })}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Field of Study"
            value={education.field}
            onChange={(e) => setEducation({ ...education, field: e.target.value })}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Graduation Year"
            type="number"
            value={education.graduationYear}
            onChange={(e) => setEducation({ ...education, graduationYear: e.target.value })}
          />
        </Grid>
      </Grid>

      <Button variant="contained" onClick={handleAdd} sx={{ mb: 3 }}>
        Add Education
      </Button>

      {formData.education.map((edu, index) => (
        <Box key={index} sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="subtitle1">
            {edu.level} in {edu.field}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {edu.institution} - {edu.graduationYear}
          </Typography>
          <Button 
            size="small" 
            color="error" 
            onClick={() => handleRemove(index)}
            sx={{ mt: 1 }}
          >
            Remove
          </Button>
        </Box>
      ))}
    </Box>
  );
} 