import { configureStore } from "@reduxjs/toolkit";

// reducers
import SideBarReducer from "./sidebar";
import ToastReducer from "./toast";
import AuthenticationReducer from "./authentication";

// Get persisted user data from localStorage
const token = localStorage.getItem('token');
let persistedUser = null;
try {
  const userData = localStorage.getItem('userData');
  if (userData) {
    persistedUser = JSON.parse(userData);
  }
} catch (error) {
  console.error('Error parsing persisted user data:', error);
}

const preloadedState = {
  authentication: {
    value: {
      isLogged: !!token,
      loginData: persistedUser,
      token: token,
      isNewUser: false
    }
  }
};

export const store = configureStore({
  reducer: {
    sidebar: SideBarReducer,
    toast: ToastReducer,
    authentication: AuthenticationReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// Handle unauthorized events
window.addEventListener('unauthorized', () => {
  store.dispatch({ type: 'authentication/logout' });
});

// Optional: Add store listener for debugging
store.subscribe(() => {
  console.log('Store updated:', store.getState());
});
