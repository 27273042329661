import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  LinearProgress,
  Chip,
  IconButton,
  CardActionArea,
} from '@mui/material';
import {
  PlayArrow,
  Code,
  Psychology,
  BusinessCenter,
  Language,
  Computer,
  Assessment,
  Group,
  Lock,
} from '@mui/icons-material';

const modules = [
  {
    id: 1,
    title: 'Technical Interview Fundamentals',
    description: 'Master the basics of data structures, algorithms, and problem-solving techniques',
    progress: 75,
    icon: <Code />,
    image: '/path/to/tech-interview.jpg',
    duration: '8 hours',
    level: 'Beginner',
    isLocked: false,
  },
  {
    id: 2,
    title: 'Behavioral Interview Mastery',
    description: 'Learn how to effectively communicate your experiences using the STAR method',
    progress: 45,
    icon: <Psychology />,
    image: '/path/to/behavioral.jpg',
    duration: '6 hours',
    level: 'Intermediate',
    isLocked: false,
  },
  {
    id: 3,
    title: 'System Design Interviews',
    description: 'Deep dive into designing scalable systems and architecture patterns',
    progress: 20,
    icon: <Computer />,
    image: '/path/to/system-design.jpg',
    duration: '10 hours',
    level: 'Advanced',
    isLocked: true,
  },
  {
    id: 4,
    title: 'Communication Skills',
    description: 'Enhance your verbal and non-verbal communication abilities',
    progress: 60,
    icon: <Language />,
    image: '/path/to/communication.jpg',
    duration: '4 hours',
    level: 'Beginner',
    isLocked: false,
  },
  {
    id: 5,
    title: 'Mock Interview Practice',
    description: 'Practice with AI-powered mock interviews and get instant feedback',
    progress: 30,
    icon: <Assessment />,
    image: '/path/to/mock-interview.jpg',
    duration: '12 hours',
    level: 'Intermediate',
    isLocked: true,
  },
  {
    id: 6,
    title: 'Industry Deep Dives',
    description: 'Learn about different tech industries and their interview processes',
    progress: 0,
    icon: <BusinessCenter />,
    image: '/path/to/industry.jpg',
    duration: '8 hours',
    level: 'Advanced',
    isLocked: true,
  },
];

export default function LearningModules() {
  const history = useHistory();

  const handleModuleClick = (moduleId) => {
    history.push(`/learning/${moduleId}`);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" class="text-white" component="h1" gutterBottom>
          Learning Modules
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Master the art of interviewing with our comprehensive learning modules
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {modules.map((module) => (
          <Grid item xs={12} sm={6} md={4} key={module.id}>
            <Card 
              elevation={2}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                opacity: module.isLocked ? 0.8 : 1,
              }}
            >
              <CardActionArea 
                disabled={module.isLocked} 
                onClick={() => !module.isLocked && handleModuleClick(module.id)}
              >
                <CardMedia
                  component="div"
                  sx={{
                    height: 140,
                    bgcolor: 'primary.dark',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {module.icon && React.cloneElement(module.icon, { 
                    sx: { fontSize: 60, color: 'primary.light' } 
                  })}
                </CardMedia>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Typography gutterBottom variant="h6" component="h2">
                      {module.title}
                    </Typography>
                    {module.isLocked && (
                      <Lock sx={{ color: 'text.secondary' }} />
                    )}
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {module.description}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <Chip 
                      label={module.duration} 
                      size="small" 
                      sx={{ bgcolor: 'rgba(144, 202, 249, 0.08)' }}
                    />
                    <Chip 
                      label={module.level} 
                      size="small"
                      sx={{ bgcolor: 'rgba(144, 202, 249, 0.08)' }}
                    />
                  </Box>
                  <Box sx={{ mt: 'auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ flexGrow: 1 }}>
                        Progress: {module.progress}%
                      </Typography>
                      {!module.isLocked && (
                        <IconButton 
                          size="small" 
                          color="primary"
                          sx={{ 
                            bgcolor: 'rgba(144, 202, 249, 0.08)',
                            '&:hover': { bgcolor: 'rgba(144, 202, 249, 0.16)' }
                          }}
                        >
                          <PlayArrow />
                        </IconButton>
                      )}
                    </Box>
                    <LinearProgress 
                      variant="determinate" 
                      value={module.progress} 
                      sx={{ 
                        height: 6, 
                        borderRadius: 3,
                        bgcolor: 'rgba(144, 202, 249, 0.08)',
                      }} 
                    />
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
} 