import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Chip,
  Rating,
  Autocomplete,
} from '@mui/material';

const skillLevels = [
  { value: 'Beginner', rating: 1 },
  { value: 'Intermediate', rating: 2 },
  { value: 'Advanced', rating: 3 },
  { value: 'Expert', rating: 4 }
];

export default function SkillsForm({ formData, setFormData }) {
  const [skill, setSkill] = React.useState('');
  const [level, setLevel] = React.useState(2);

  const handleAddSkill = (event) => {
    if (event.key === 'Enter' && skill.trim()) {
      const newSkill = {
        name: skill.trim(),
        level: skillLevels.find(l => l.rating === level)?.value || 'Intermediate',
        endorsements: 0
      };
      
      setFormData({
        ...formData,
        skills: [...formData.skills, newSkill]
      });
      setSkill('');
      setLevel(2);
    }
  };

  const handleDelete = (index) => {
    const newSkills = [...formData.skills];
    newSkills.splice(index, 1);
    setFormData({
      ...formData,
      skills: newSkills
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Technical Skills
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="Add a skill (press Enter)"
          value={skill}
          onChange={(e) => setSkill(e.target.value)}
          onKeyPress={handleAddSkill}
          sx={{ mb: 2 }}
        />
        <Typography component="legend">Skill Level</Typography>
        <Rating
          value={level}
          onChange={(event, newValue) => {
            setLevel(newValue);
          }}
          max={4}
        />
      </Box>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {formData.skills.map((item, index) => (
          <Chip
            key={index}
            label={`${item.name} (${item.level})`}
            onDelete={() => handleDelete(index)}
            color="primary"
            variant="outlined"
          />
        ))}
      </Box>
    </Box>
  );
} 