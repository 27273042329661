import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  LinearProgress,
} from '@mui/material';
import {
  MoreVert,
  Business,
  CalendarToday,
  Assignment,
} from '@mui/icons-material';
import { applicationApi } from '../../../api/applicationApi';

const statusColors = {
  Draft: 'default',
  Submitted: 'primary',
  'Under Review': 'info',
  Interview: 'warning',
  Offer: 'success',
  Rejected: 'error',
};

export default function ApplicationCard({ application, onStatusUpdate }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      setLoading(true);
      await applicationApi.updateApplicationStatus(application._id, {
        status: newStatus,
      });
      onStatusUpdate();
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      setLoading(false);
      handleMenuClose();
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6" component="div">
            {application.position}
          </Typography>
          <IconButton onClick={handleMenuClick}>
            <MoreVert />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Business sx={{ mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {application.companyName}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CalendarToday sx={{ mr: 1, fontSize: 'small' }} />
          <Typography variant="body2" color="text.secondary">
            Applied: {new Date(application.submissionDate).toLocaleDateString()}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Chip
            label={application.status}
            color={statusColors[application.status]}
            size="small"
          />
          {application.interviews?.length > 0 && (
            <Chip
              icon={<Assignment />}
              label={`${application.interviews.length} Interview${
                application.interviews.length > 1 ? 's' : ''
              }`}
              size="small"
              variant="outlined"
            />
          )}
        </Box>

        {loading && <LinearProgress sx={{ mt: 2 }} />}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {Object.keys(statusColors).map((status) => (
            <MenuItem
              key={status}
              onClick={() => handleStatusChange(status)}
              disabled={application.status === status}
            >
              Mark as {status}
            </MenuItem>
          ))}
        </Menu>
      </CardContent>
    </Card>
  );
} 