import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import InterestsForm from './steps/InterestsForm';
import EducationForm from './steps/EducationForm';
import ExperienceForm from './steps/ExperienceForm';
import SkillsForm from './steps/SkillsForm';
import TargetCompaniesForm from './steps/TargetCompaniesForm';
import JobSearchPreferencesForm from './steps/JobSearchPreferencesForm';
import { useHistory } from 'react-router-dom';
import { profileApi } from '../../api/profileApi';
import { updateUser } from '../../library/store/authentication';
import { checkAuthStatus } from '../../library/store/authentication';
import { toggleSidebar } from '../../library/store/sidebar';

const steps = [
  'Interests & Hobbies',
  'Education',
  'Experience',
  'Skills',
  'Target Companies',
  'Job Search Preferences',
];

export default function Questionnaire() {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    interests: [],
    hobbies: [],
    education: [],
    experience: [],
    skills: [],
    targetCompanies: [],
    preferredRoles: [],
    jobSearchStatus: 'actively_looking',
    networkingPreferences: {
      openToMentoring: false,
      openToConnecting: true,
      preferredIndustries: []
    }
  });
  
  const history = useHistory();
  const dispatch = useDispatch();
  const { loginData, token } = useSelector((state) => state.authentication.value);
  const userData = useSelector((state) => state.authentication?.value?.loginData);

  useEffect(() => {
    let mounted = true;

    // Redirect to login if not authenticated
    if (!token || !loginData?._id) {
      history.push('/login');
    }

    // Force sidebar to minimize on mount
    if (mounted && token) {
      dispatch(toggleSidebar(false));
    }

    return () => {
      mounted = false;
    };
  }, [token, loginData, history, dispatch]);

  const handleNext = useCallback(() => {
    if (validateStep(activeStep)) {
      setActiveStep((prev) => prev + 1);
    } else {
      setError('Please complete all required fields before proceeding');
    }
  }, [activeStep, validateStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      if (!userData?._id) {
        throw new Error('User ID not found');
      }

      // Create profile - this will now include strength evaluation
      const profileResponse = await profileApi.createProfile(userData._id, formData);

      if (!profileResponse.success) {
        throw new Error(profileResponse.message || 'Failed to create profile');
      }

      // Update user's profile completion status
      await dispatch(updateUser({
        userId: userData._id,
        userData: { 
          profileCompleted: true,
          profileStrength: profileResponse.data.profileStrength
        }
      }));

      // Refresh auth state
      await dispatch(checkAuthStatus());

      // Show success message or redirect
      history.push('/dashboard');
    } catch (error) {
      console.error('Error creating profile:', error);
      setError(error.message || 'Failed to create profile');
    } finally {
      setIsSubmitting(false);
    }
  }, [userData, formData, dispatch, history]);

  const getStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return (
          <InterestsForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <EducationForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <ExperienceForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <SkillsForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <TargetCompaniesForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 5:
        return (
          <JobSearchPreferencesForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      default:
        return 'Unknown step';
    }
  }, [formData]);

  const validateStep = useCallback((step) => {
    switch (step) {
      case 0: // Interests & Hobbies
        return formData.interests.length > 0 || formData.hobbies.length > 0;
      case 1: // Education
        return formData.education.length > 0;
      case 2: // Experience
        return formData.experience.length > 0;
      case 3: // Skills
        return formData.skills.length > 0;
      case 4: // Target Companies
        return formData.targetCompanies.length > 0;
      case 5: // Job Search Preferences
        return formData.jobSearchStatus !== '';
      default:
        return true;
    }
  }, [formData]);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Let's Get to Know You
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Stepper activeStep={activeStep} sx={{ mt: 3, mb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          {activeStep === steps.length ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5" gutterBottom>
                All steps completed!
              </Typography>
              <Button 
                onClick={handleSubmit} 
                variant="contained" 
                disabled={isSubmitting}
                sx={{ mt: 2 }}
              >
                {isSubmitting ? 'Creating Profile...' : 'Create Profile'}
              </Button>
            </Box>
          ) : (
            <>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
} 