import React from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Switch,
  FormControlLabel,
} from '@mui/material';

export default function ExperienceForm({ formData, setFormData }) {
  const [experience, setExperience] = React.useState({
    title: '',
    company: '',
    startDate: '',
    endDate: '',
    current: false,
    description: ''
  });

  const handleAdd = () => {
    if (experience.title && experience.company) {
      setFormData({
        ...formData,
        experience: [...formData.experience, experience]
      });
      setExperience({
        title: '',
        company: '',
        startDate: '',
        endDate: '',
        current: false,
        description: ''
      });
    }
  };

  const handleRemove = (index) => {
    const newExperience = [...formData.experience];
    newExperience.splice(index, 1);
    setFormData({
      ...formData,
      experience: newExperience
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Work Experience
      </Typography>
      
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Job Title"
            value={experience.title}
            onChange={(e) => setExperience({ ...experience, title: e.target.value })}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Company"
            value={experience.company}
            onChange={(e) => setExperience({ ...experience, company: e.target.value })}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            value={experience.startDate}
            onChange={(e) => setExperience({ ...experience, startDate: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="End Date"
            type="date"
            value={experience.endDate}
            onChange={(e) => setExperience({ ...experience, endDate: e.target.value })}
            InputLabelProps={{ shrink: true }}
            disabled={experience.current}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={experience.current}
                onChange={(e) => setExperience({ ...experience, current: e.target.checked })}
              />
            }
            label="Current Position"
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            value={experience.description}
            onChange={(e) => setExperience({ ...experience, description: e.target.value })}
          />
        </Grid>
      </Grid>

      <Button variant="contained" onClick={handleAdd} sx={{ mb: 3 }}>
        Add Experience
      </Button>

      {formData.experience.map((exp, index) => (
        <Box key={index} sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="subtitle1">
            {exp.title} at {exp.company}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {new Date(exp.startDate).toLocaleDateString()} - 
            {exp.current ? 'Present' : new Date(exp.endDate).toLocaleDateString()}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {exp.description}
          </Typography>
          <Button 
            size="small" 
            color="error" 
            onClick={() => handleRemove(index)}
            sx={{ mt: 1 }}
          >
            Remove
          </Button>
        </Box>
      ))}
    </Box>
  );
} 