import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  LinearProgress,
} from '@mui/material';

export default function ApplicationStats({ stats }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Total Applications
          </Typography>
          <Typography variant="h4" color="primary">
            {stats.total || 0}
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={(stats.total / 100) * 100} 
            sx={{ mt: 1 }}
          />
        </Paper>
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Active Applications
          </Typography>
          <Typography variant="h4" color="info.main">
            {stats.active || 0}
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={(stats.active / stats.total) * 100} 
            color="info"
            sx={{ mt: 1 }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Interviews
          </Typography>
          <Typography variant="h4" color="warning.main">
            {stats.interviews || 0}
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={(stats.interviews / stats.total) * 100} 
            color="warning"
            sx={{ mt: 1 }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Offers
          </Typography>
          <Typography variant="h4" color="success.main">
            {stats.offers || 0}
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={(stats.offers / stats.total) * 100} 
            color="success"
            sx={{ mt: 1 }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
} 