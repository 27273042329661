import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userApi } from '../../api/userApi';
import axiosInstance from '../../api/axiosConfig';

// Create async thunks
export const authenticateUser = createAsyncThunk(
  'authentication/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await userApi.login(credentials);
      if (response.success && response.token && response.user) {
        localStorage.setItem('token', response.token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
        return {
          success: true,
          token: response.token,
          user: response.user
        };
      }
      return rejectWithValue('Login failed: Invalid response from server');
    } catch (error) {
      return rejectWithValue(error.message || 'Login failed');
    }
  }
);

export const checkAuthStatus = createAsyncThunk(
  'authentication/verify',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.verifyToken();
      console.log('Verify response:', response);
      if (!response || !response.success || !response.user) {
        throw new Error('Invalid response from server');
      }
      return response;
    } catch (error) {
      console.error('Verify error:', error);
      return rejectWithValue(error.message || 'Token verification failed');
    }
  }
);

export const registerUser = createAsyncThunk(
  'authentication/register',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await userApi.createUser(userData);
      if (response.success && response.token && response.user) {
        localStorage.setItem('token', response.token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
        return {
          success: true,
          token: response.token,
          user: response.user
        };
      }
      return rejectWithValue('Registration failed: Invalid response from server');
    } catch (error) {
      return rejectWithValue(error.message || 'Registration failed');
    }
  }
);

export const updateUser = createAsyncThunk(
  'authentication/update',
  async ({ userId, userData }, { rejectWithValue }) => {
    try {
      const response = await userApi.updateUser(userId, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || 'Update failed');
    }
  }
);

export const checkProfileStatus = createAsyncThunk(
  'authentication/checkProfile',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await userApi.checkUserExists(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  value: {
    isLogged: false,
    token: localStorage.getItem('token'),
    loginData: null,
    isNewUser: false,
    error: null,
    loading: false
  }
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      delete axiosInstance.defaults.headers.common['Authorization'];
      state.value = {
        ...initialState.value,
        token: null,
        isLogged: false
      };
    },
    setLoginData: (state, action) => {
      state.value.loginData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.value.loading = true;
        state.value.error = null;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.value.loading = false;
        state.value.isLogged = true;
        state.value.token = action.payload.token;
        state.value.loginData = action.payload.user;
        state.value.error = null;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.value.loading = false;
        state.value.error = action.payload;
        state.value.isLogged = false;
        state.value.token = null;
        state.value.loginData = null;
      })
      .addCase(checkAuthStatus.pending, (state) => {
        state.value.loading = true;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        console.log('Fulfilled payload:', action.payload);
        if (action.payload?.success && action.payload?.user) {
          state.value.loading = false;
          state.value.isLogged = true;
          state.value.loginData = action.payload.user;
          state.value.isNewUser = action.payload.isNewUser;
          state.value.error = null;
        } else {
          state.value.loading = false;
          state.value.isLogged = false;
          state.value.loginData = null;
          state.value.error = 'Invalid response from server';
          localStorage.removeItem('token');
        }
      })
      .addCase(checkAuthStatus.rejected, (state, action) => {
        state.value.loading = false;
        state.value.isLogged = false;
        state.value.token = null;
        state.value.loginData = null;
        state.value.error = action.payload;
        localStorage.removeItem('token');
      })
      .addCase(registerUser.pending, (state) => {
        state.value.loading = true;
        state.value.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.value.loading = false;
        state.value.isLogged = true;
        state.value.token = action.payload.token;
        state.value.loginData = action.payload.user;
        state.value.isNewUser = true;
        state.value.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.value.loading = false;
        state.value.error = action.payload;
        state.value.isLogged = false;
        state.value.token = null;
        state.value.loginData = null;
      });
  }
});

export const { logout, setLoginData } = authenticationSlice.actions;
export default authenticationSlice.reducer;
