import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkAuthStatus } from '../library/store/authentication';
import { Box, CircularProgress } from '@mui/material';

export default function AuthenticatedLayout({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isVerifying, setIsVerifying] = useState(true);
  const { isLogged, loading, loginData, error } = useSelector((state) => state.authentication.value);
  const token = localStorage.getItem('token');

  useEffect(() => {
    let mounted = true;

    const verifyAuth = async () => {
      try {
        console.log("token " + token)
        if (!token) {
          if (mounted) {
            setIsVerifying(false);
            history.push('/login');
          }
          return;
        }

        const result = await dispatch(checkAuthStatus()).unwrap();
        if (mounted) {
          if (!result?.success || !result?.user) {
            console.error('Invalid verification response:', result);
            history.push('/login');
          }
          setIsVerifying(false);
        }
      } catch (error) {
        console.error('Auth verification error:', error);
        if (mounted) {
          history.push('/login');
          setIsVerifying(false);
        }
      }
    };

    verifyAuth();

    return () => {
      mounted = false;
    };
  }, [dispatch, history, token]);

  if (error) {
    console.error('Authentication error:', error);
  }

  if (isVerifying || loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isLogged || !token || !loginData) {
    history.push('/login');
    return null;
  }

  return <>{children}</>;
} 