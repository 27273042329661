import React from 'react';
import {
  Box,
  TextField,
  Chip,
  Typography,
} from '@mui/material';

export default function InterestsForm({ formData, setFormData }) {
  const [interest, setInterest] = React.useState('');
  const [hobby, setHobby] = React.useState('');

  const handleAddInterest = (event) => {
    if (event.key === 'Enter' && interest.trim()) {
      setFormData({
        ...formData,
        interests: [...formData.interests, interest.trim()]
      });
      setInterest('');
    }
  };

  const handleAddHobby = (event) => {
    if (event.key === 'Enter' && hobby.trim()) {
      setFormData({
        ...formData,
        hobbies: [...formData.hobbies, hobby.trim()]
      });
      setHobby('');
    }
  };

  const handleDelete = (type, index) => {
    const newData = [...formData[type]];
    newData.splice(index, 1);
    setFormData({
      ...formData,
      [type]: newData
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Professional Interests
      </Typography>
      <TextField
        fullWidth
        label="Add an interest (press Enter)"
        value={interest}
        onChange={(e) => setInterest(e.target.value)}
        onKeyPress={handleAddInterest}
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 4 }}>
        {formData.interests.map((item, index) => (
          <Chip
            key={index}
            label={item}
            onDelete={() => handleDelete('interests', index)}
          />
        ))}
      </Box>

      <Typography variant="h6" gutterBottom>
        Hobbies
      </Typography>
      <TextField
        fullWidth
        label="Add a hobby (press Enter)"
        value={hobby}
        onChange={(e) => setHobby(e.target.value)}
        onKeyPress={handleAddHobby}
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {formData.hobbies.map((item, index) => (
          <Chip
            key={index}
            label={item}
            onDelete={() => handleDelete('hobbies', index)}
          />
        ))}
      </Box>
    </Box>
  );
} 