import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Switch,
  MenuItem,
  Chip,
  Autocomplete
} from '@mui/material';

const jobSearchStatuses = [
  'actively_looking',
  'open_to_opportunities',
  'not_looking',
  'employed_but_looking'
];

const commonIndustries = [
  'Technology',
  'Healthcare',
  'Finance',
  'Education',
  'Manufacturing',
  'Retail',
  'Media',
  'Consulting',
  'Non-profit',
  'Other'
];

export default function JobSearchPreferencesForm({ formData, setFormData }) {
  const handleStatusChange = (event) => {
    setFormData({
      ...formData,
      jobSearchStatus: event.target.value
    });
  };

  const handleNetworkingChange = (field) => (event) => {
    setFormData({
      ...formData,
      networkingPreferences: {
        ...formData.networkingPreferences,
        [field]: event.target.checked
      }
    });
  };

  const handlePreferredRolesChange = (event, newValue) => {
    setFormData({
      ...formData,
      preferredRoles: newValue
    });
  };

  const handleIndustriesChange = (event, newValue) => {
    setFormData({
      ...formData,
      networkingPreferences: {
        ...formData.networkingPreferences,
        preferredIndustries: newValue
      }
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Job Search Preferences
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              label="Job Search Status"
              value={formData.jobSearchStatus}
              onChange={handleStatusChange}
            >
              {jobSearchStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status.split('_').map(word => 
                    word.charAt(0).toUpperCase() + word.slice(1)
                  ).join(' ')}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={formData.preferredRoles}
            onChange={handlePreferredRolesChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Preferred Roles"
                placeholder="Add preferred job roles"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Networking Preferences
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={formData.networkingPreferences.openToMentoring}
                onChange={handleNetworkingChange('openToMentoring')}
              />
            }
            label="Open to Mentoring"
          />
          <FormControlLabel
            control={
              <Switch
                checked={formData.networkingPreferences.openToConnecting}
                onChange={handleNetworkingChange('openToConnecting')}
              />
            }
            label="Open to Connecting"
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={commonIndustries}
            value={formData.networkingPreferences.preferredIndustries}
            onChange={handleIndustriesChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Preferred Industries"
                placeholder="Select industries"
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
} 